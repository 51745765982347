.modern-input-float {
  background: transparent;
  position: relative;
  margin: 40px 0 20px;
}

.modern-input-float-container {
  top: 0;
  left: 0;
  width: 300px;
  display: flex;
  position: relative;
  justify-content: center;
  /*  */
  max-width: min(800px, calc(100% - 80px));
  /* good for mobile development, 
  there is a margin no matter the size of the screen */
  margin: 0 auto;
  /* border: 1px solid rgb(255, 0, 200); */
}

input {
  color: #ffffff;
  font-size: 18px;
  font-family: "Audiowide";
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid #ffffffaf;
}

input:focus {
  outline: none;
}

label {
  color: #ffffffaf;
  font-size: 20px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 30px;
  transition: 0.2s ease all;
}

input:focus ~ label,
input:valid ~ label {
  top: 20px;
  font-size: 18px;
  color: #ffffff;
}

.bar {
  position: absolute;
  display: block;
  top: 85px;
  width: 300px;
}

.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #ffffff;
  transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}
