a {
  position: relative;
  font-size: 30px;
  color: #ffffffaf;
  text-decoration: none;
}
a:before {
  content: "";
  position: absolute;
  bottom: -2px;
  height: 2px;
  width: 0;
  background: #ffffffaf;
  border-radius: 15px;
  transition: width 0.3s ease;
}
a:hover {
  width: 100%;
  color: #ffffff;
}
a:hover::before {
  width: 100%;
  color: #ffffff;
}
