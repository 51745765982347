.container {
  margin: 0 auto;
}
.chat-log-card {
  background-color: whitesmoke;
  text-align: center;
  font-size: 20px;
  margin: 1vh;
  border: 5px solid black;
  border-radius: 40px;
  display: inline-block;
  padding: 20px;
  opacity: 1;
  transition: 200ms ease-in-out;
  position: relative;
  min-width: 350px;
  margin-right: 70%;
}
.chat-log-card:hover {
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.8);
  background-color: white;
  min-width: 400px;
}
.chat-log-card[data-local-open="false"] {
  animation: 1s ease 0s 1 normal forwards running slideInFromRight,
    3s ease 0s 1 normal none running fadeIn;
  color: grey;
  margin-right: 70%;
  padding: 20px;
  background-color: whitesmoke;
}
.chat-log-card[data-local-open="true"] {
  animation: 1s ease 0s 1 normal forwards running slideInFromRight,
    3s ease 0s 1 normal none running fadeIn;
  color: grey;
  margin-right: 0%;
  padding: 250px;
  background-color: white;
}
.chat-log-card[data-self="false"] {
  color: grey;
}
.chat-log-card[data-self="true"] {
  color: black;
}
