html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

div.stickyHeader {
  /* box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5); */
  z-index: 1;
  /* background-color: rgb(59, 97, 180); */
  /* border: 1px solid rgb(255, 0, 200); */
  opacity: 1;
}
