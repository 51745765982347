body {
  text-align: center;
  background: black;
  /* linear-gradient(to top, rgb(36, 30, 71), rgb(9, 5, 31)); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  font-family: "Audiowide";
  font-size: 30px;
  height: 100vh;
  color: #ffffffaf;
}

* {
  padding: 0; /*inside on the element, margin is outside of the element*/
  margin: 0;
}
