.mainDiv {
  /*  display: flex; */
  justify-content: center;
}

@media screen and (max-width: 410px) {
  div.resizeFontOnDisplay {
    font-size: 20px;
  }
}

.home-messagePopUp {
  font-size: 40px;
  animation: fade-in-mess 10s, slideRight 10s; /* if you add in infitie, it will be infinite, if you add in alternate, it will alternate*/
}

.messagePopUp {
  font-size: 40px;
  animation: fade-in-mess 10s, slideRight 10s; /* if you add in infitie, it will be infinite, if you add in alternate, it will alternate*/
}

.temple {
  max-width: 55%;
  height: auto;
}

.titleText {
  display: flex;
  justify-content: center;
  /* border: 1px solid rgb(147, 187, 86); */
}

.bigBox {
  height: 550px;
  display: grid;
  place-items: center;
  /* border: 1px solid rgb(147, 187, 86); */
}

.homeTextDivider {
  width: 500px;
  height: 200px;
  top: 20%;
  left: 37%;
  /* border: 1px solid greenyellow; */
}

.fixedHeaderDivider {
  height: fit-content;
  width: fit-content;
  /* border: 1px solid rgb(147, 187, 86); */
}

.header {
  display: flex;
  justify-content: center;
  padding: 15px;
  padding-left: 25px;
  /*border: 1px solid rgb(147, 187, 86); */
}

.body {
  display: flex;
  justify-content: space-around;
  /* border: 1px solid hotpink; */
}

.linksDivider {
  display: flex;
  height: fit-content;
  width: fit-content; /* width of the container depends on the content inside */
  column-gap: 30px;
  /*border: 1px solid rgb(252, 255, 54); */
}

.buttonBox {
  width: 90px;
  height: 100px;
  top: 20%;
  left: 25%;
  padding: 50px;
  padding-bottom: 15px;
  /* border: 1px solid yellow; */
}

.divInputBox {
  width: 400px;
  height: auto;
  padding: 0px;
  padding-bottom: 50px;
  /* border: 1px solid rgb(51, 0, 255); */

  opacity: 1;
  /* animation: fadeIn 3s forwards; */
  /* animation-delay: 3s; */
}

.divInputBox::after {
  opacity: 1;
}

.statue {
  max-width: 40%;
  height: auto;
  left: 5%;
  top: 15%;
  border-radius: 2%;
}
.VVV {
  max-width: 25%;
  height: auto;
  opacity: 0.8;
}
.gathering {
  max-width: 100%;
  height: auto;
}
.no-scroll {
  height: 100%;
  overflow: hidden;
}
