.round-hover-input {
  width: 30%;
  /* margin: 10px; command + / for comment*/
  color: grey; /* may need to chagne */
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 100px;
  border: 1px solid #eee;
  transition: 200ms ease-in-out;
}
.round-hover-input:hover {
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.8);
}
.round-hover-input:focus {
  outline: none;
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.8);
}

.round-hover-input-box-container {
  top: 0;
  left: 0;
  display: flex;
  position: relative;
  justify-content: center;
  gap: 10px;
  /*  */
  max-width: min(800px, calc(100% - 80px));
  /* good for mobile development, 
  there is a margin no matter the size of the screen */
  margin: 0 auto;
}

.round-hover-input-box-container > * {
  /* need to learn flex-grow and flex-basis */
  flex-grow: 1;
  flex-basis: 0;
}

.round-hover-input-box-container > h3 {
  text-align: left;
}
