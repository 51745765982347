.primary-button-button {
  display: inline-block;
  border-radius: var(--standard-border-radius);
  font-family: "Audiowide";
  background-color: black;
  /*   background-color: var(--logos-blue-dark); */
  border: 2px solid grey;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  padding: 0px;
  width: 200px;
  transition: all 150ms ease-in-out;
  cursor: pointer;
  margin: 5px;
  top: 0%;
  left: 0%;
  opacity: 1;
  animation-fill-mode: forwards;
}
/* on hover shift the whole text to the left and change the background to grey */
.primary-button-button:hover {
  padding-right: 0px;
  background-color: grey;
}

/* Button Hover Arrow. Slow first then just goes out

.primary-button-button::after {
  content: ">>";
  opacity: 1;
  padding-left: 10px;
  transition: all 100ms ease-in-out;
}
.primary-button-button:hover::after {
  opacity: 1;
  right: 0px;
}

*/
