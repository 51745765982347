.welcome-titlePopUp {
  /*border: 1px solid cyan;*/
  /* border: 1px solid hotpink; */
  width: fit-content;
  height: auto;
  font-size: 80px;
  opacity: 1;
  top: 0;
  left: 0;
}

.welcome-titlePopUp::after {
  transition: all 100ms ease-in-out;
  opacity: 1;
}

@media screen and (max-width: 410px) {
  div.resizeFontOnDisplay {
    font-size: 20px;
    padding-top: 0px;
  }
}
